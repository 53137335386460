import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const CommunityGuidelinesPage = () => (
  <Layout>
    <SEO title="Community Guidelines" />
    <h1 className="text-4xl font-semibold text-center pb-10 px-10">
      Community Guidelines
    </h1>
    <div className="flex flex-col justify-center items-center px-10">
      <p className="max-w-xl pb-5">
        Simmr isn’t a place for antagonistic, explicit, false or misleading,
        harmful, hateful, or violent content or behavior. All of our Community
        Guidelines apply in notes, messages, and any user content. We may remove
        or limit the distribution of such content and the accounts that save it.
        We determine whether content should be limited or removed based on how
        much harm it poses. Such content includes:
      </p>
      <ol className="max-w-xl">
        <li className="pb-5">
          <span className="font-semibold">I. Adult Content:</span> Fetish
          imagery, vivid sexual descriptions, graphic depictions of sexual
          activity, and images of nudity.
        </li>
        <li className="pb-5">
          <span className="font-semibold">II. Exploitation:</span>
          Non-consensual images, adult sexual services that may involve
          sexual/physical/financial exploitation or trafficking, human
          trafficking and other illegal commercial exploitation, sexualization
          or sexual exploitation of minors.
        </li>
        <li className="pb-5">
          <span className="font-semibold">III. Hateful Activities:</span> Slurs
          or negative stereotypes, caricatures and generalizations; support for
          hate groups and people promoting hateful activities, prejudice and
          conspiracy theories; condoning or trivializing violence because of a
          victim’s membership in a vulnerable or protected group; support for
          white supremacy, limiting women’s rights and other discriminatory
          ideas; Hate-based conspiracy theories and misinformation (like
          Holocaust denial); denial of an individual’s gender identity or sexual
          orientation, and support for conversion therapy and related programs;
          attacks on individuals; mocking or attacking the beliefs, sacred
          symbols, movements, or institutions of the protected vulnerable groups
          identified below:
          <ol className="pb-5 flex justify-center">
            <li className="max-w-lg">
              A. People grouped together based on their actual or perceived
              race, color, caste, ethnicity, immigration status, national
              origin, religion or faith, sex or gender identity, disability or
              medical condition. It also includes people who are grouped
              together based on lower socio-economic status, age, weight or
              size, pregnancy or veteran status.
            </li>
          </ol>
        </li>
        <li className="pb-5">
          <span className="font-semibold">IV. Misinformation:</span> Medically
          unsupported health claims that risk public health and safety,
          including the promotion of false cures, anti-vaccination advice, or
          misinformation about public health or safety emergencies; false or
          misleading content about individuals or protected groups that promotes
          fear, hate or prejudice; false or misleading content that encourages
          turning individuals, groups of people, places or organizations into
          targets of harassment or physical violence; conspiracy theories; False
          or misleading content that impedes an election’s integrity or an
          individual's or group's civic participation, including registering to
          vote, voting, and being counted in a census; content that originates
          from disinformation campaigns; factual information that’s published or
          deliberately modified to erode trust or inflict harm, such as changing
          or omitting of context, date or time; fabricated or meaningfully
          manipulated visual or audio content that erodes trust or causes harm.
        </li>
        <li className="pb-5">
          <span className="font-semibold">V. Harassment and Criticism:</span>
          Manipulated images intended to degrade or shame; criticisms involving
          name-calling, profanity and other insulting language or imagery;
          mocking someone for experiencing sadness, grief, loss of outrage;
          shaming people for their bodies or assumed sexual and romantic
          history; sexual remarks about people’s bodies and solicitations or
          offers of sexual acts.
        </li>
        <li className="pb-5">
          <span className="font-semibold">
            VI. Self-injury and Harmful Behavior:
          </span>
          Self-harm instructions; suicidal thinking and quotes; graphic or
          otherwise triggering imagery; promotion of self-harm; mocking of
          people who self-harm or who have attempted to die by suicide; images
          of accessories used to self-harm; negative self-talk and insensitive
          humor about self-harming behavior.
        </li>
        <li className="pb-5">
          <span className="font-semibold">VII. Violence:</span> Content that
          shows the use of violence; disturbing scenes from before or after
          violent events; threats or language that glorifies violence.
        </li>
      </ol>
    </div>
  </Layout>
)

export default CommunityGuidelinesPage
